// Generated by Framer (d9af232)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Video } from "https://framerusercontent.com/modules/lRDHiNWNVWmE0lqtoVHP/IZ0vSV62Dv7ax4rBiGUk/Video.js";
import { videoAnimation } from "../codeFile/VideoRolagem.tsx";
import * as localizedValues from "./Wd77AjJIW-0.js";
const VideoFonts = getFonts(Video);
const MotionDivVideoAnimation = videoAnimation(motion.div);

const cycleOrder = ["j_Et_C4ql"];

const serializationHash = "framer-jJ2zP"

const variantClassNames = {j_Et_C4ql: "framer-v-1ya1b0u"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {C3NO8bylo: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "j_Et_C4ql", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><MotionDivVideoAnimation {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1ya1b0u", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"j_Et_C4ql"} ref={ref ?? ref1} style={{backgroundColor: "rgb(51, 136, 255)", ...style}}><motion.div className={"framer-1egnl4x"} layoutDependency={layoutDependency} layoutId={"VeeB9BwQ6"} style={{backgroundColor: "rgb(255, 255, 255)"}}><ComponentViewportProvider ><motion.div className={"framer-5bnxuv-container"} layoutDependency={layoutDependency} layoutId={"FBnVf0FnH-container"}><Video backgroundColor={"rgba(0, 0, 0, 0)"} borderRadius={0} bottomLeftRadius={0} bottomRightRadius={0} controls={false} height={"100%"} id={"FBnVf0FnH"} isMixedBorderRadius={false} layoutId={"FBnVf0FnH"} loop muted objectFit={"cover"} playing={false} posterEnabled={false} srcFile={"https://framerusercontent.com/assets/DQGUMKtOw4Gc8LW7mlQSEbZFun0.mp4"} srcType={"Upload"} srcUrl={getLocalizedValue("v0", activeLocale) ?? "https://assets.mixkit.co/videos/preview/mixkit-shining-sun-in-the-sky-surrounded-by-moving-clouds-31793-small.mp4"} startTime={0} style={{height: "100%", width: "100%"}} topLeftRadius={0} topRightRadius={0} volume={25} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></MotionDivVideoAnimation></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jJ2zP.framer-fp7m48, .framer-jJ2zP .framer-fp7m48 { display: block; }", ".framer-jJ2zP.framer-1ya1b0u { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 1164px; justify-content: flex-start; overflow: visible; padding: 0px; position: relative; width: 952px; }", ".framer-jJ2zP .framer-1egnl4x { flex: none; height: 634px; overflow: visible; position: sticky; top: 0px; width: 606px; will-change: transform; z-index: 1; }", ".framer-jJ2zP .framer-5bnxuv-container { flex: none; height: 352px; left: calc(48.51485148514853% - 300px / 2); position: absolute; top: 52px; width: 300px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-jJ2zP.framer-1ya1b0u { gap: 0px; } .framer-jJ2zP.framer-1ya1b0u > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-jJ2zP.framer-1ya1b0u > :first-child { margin-top: 0px; } .framer-jJ2zP.framer-1ya1b0u > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 1164
 * @framerIntrinsicWidth 952
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerWd77AjJIW: React.ComponentType<Props> = withCSS(Component, css, "framer-jJ2zP") as typeof Component;
export default FramerWd77AjJIW;

FramerWd77AjJIW.displayName = "Teste de video";

FramerWd77AjJIW.defaultProps = {height: 1164, width: 952};

addFonts(FramerWd77AjJIW, [{explicitInter: true, fonts: []}, ...VideoFonts], {supportsExplicitInterCodegen: true})